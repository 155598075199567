import React from 'react';
import PropTypes from 'prop-types';
import { Times } from '@styled-icons/fa-solid/Times';
import { createPortal } from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { background, space } from 'styled-system';

import Container from './Container';

const ModalWrapper = styled(Container).attrs(props => ({
  maxWidth: props.maxWidth || '95%',
  maxHeight: props.maxHeight || '100%',
}))`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  border: 1px solid rgba(9, 10, 10, 0.12);
  border-radius: 8px;
  overflow-y: auto;

  ${space};
  ${background};
`;

ModalWrapper.defaultProps = {
  background: 'white',
  padding: '20px',
};

const GlobalModalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2500;
  display: block;
`;

const Header = styled(Container)`
  font-size: 20px;
  color: #090a0a;
  font-weight: 600;
  display: flex;
  text-shadow: none;
  justify-content: space-between;
`;

export const ModalBody = styled(Container)``;

ModalBody.defaultProps = {
  mt: '10px',
  mb: '30px',
};

ModalBody.propTypes = {
  /** width of the modal component */
  width: PropTypes.string,
  /** height of the modal component */
  height: PropTypes.string,
  /** children */
  children: PropTypes.node,
};

const Divider = styled.div`
  margin: 2rem 0;
  width: 100%;
  height: 1px;
  background-color: #e1e4e6;
`;

const CloseIcon = styled(Times)`
  font-size: 12px;
  width: 15px;
  height: 15px;
  color: #dadada;
  cursor: pointer;
`;

export const ModalHeader = ({ children, onClose, ...props }) => (
  <Header {...props}>
    {children || <div />}
    <CloseIcon onClick={onClose} />
  </Header>
);

ModalHeader.propTypes = {
  /** handles how the modal is closed */
  onClose: PropTypes.func,
  /** children */
  children: PropTypes.node,
};

ModalHeader.displayName = 'Header';

export const ModalFooter = ({ children, ...props }) => (
  <Container {...props}>
    <Divider />
    {children}
  </Container>
);

ModalFooter.propTypes = {
  children: PropTypes.node,
};

/**
 * Modal component. Will pass down additional props to `ModalWrapper`, which is
 * a styled `Container`.
 */
const StyledModal = ({ children, show, onClose, usePortal, ...props }) => {
  if (show && usePortal === false) {
    return (
      <React.Fragment>
        <GlobalModalStyle />
        <ModalWrapper {...props}>
          {React.Children.map(children, child => {
            if (child.type.displayName === 'Header') {
              return React.cloneElement(child, { onClose });
            }
            return child;
          })}
        </ModalWrapper>
      </React.Fragment>
    );
  }
  if (show && typeof document !== 'undefined') {
    return createPortal(
      <React.Fragment>
        <GlobalModalStyle />
        <ModalWrapper {...props}>
          {React.Children.map(children, child => {
            if (child.type?.displayName === 'Header') {
              return React.cloneElement(child, { onClose });
            }
            return child;
          })}
        </ModalWrapper>
        <ModalOverlay onClick={onClose} />
      </React.Fragment>,
      document.body,
    );
  } else {
    return null;
  }
};

StyledModal.propTypes = {
  /** a boolean to determin when to show modal */
  show: PropTypes.bool.isRequired,
  /** width of the modal component */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  /** height of the modal component */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  /** width of the modal component */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** height of the modal component */
  maxWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** width of the modal component */
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** height of the modal component */
  minWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** handles how the modal is closed */
  onClose: PropTypes.func.isRequired,
  /** wether to render the modal at the root with a potal */
  usePortal: PropTypes.bool,
  /** children */
  children: PropTypes.node,
};

StyledModal.defaultProps = {
  usePortal: true,
};

/** @component */
export default StyledModal;
